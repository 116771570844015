<template>
    <LiefengContent>
        <template v-slot:title>菜单管理</template>
        <template v-slot:toolsbarRight>
            <Input :maxlength="100" style="width: 200px" v-model.trim="menuName" placeholder="请输入菜单名称"></Input>
            <Button type="primary" icon="ios-search-outline" @click="search" style="margin-right: 10px">查询</Button>
            <Button type="success" @click="reset" icon="ios-refresh">重置</Button>
        </template>
        <template v-slot:contentArea>
            <div class="content">
                <div class="item" v-for="(menu, index) in menuList" :key="menu.id + index">
                    <div class="none-child">
                        <div class="none-div">
                            <img v-if="menu.icon && menu.icon != ''" class="img" :src="menu.icon" style="width: 16px; height: 16px" />
                            {{ menu.name }}
                        </div>
                    </div>
                    <div class="have-child" v-if="menu.subMenus && menu.subMenus.length > 0">
                        <div class="have-child-child" v-for="submenu in menu.subMenus" :key="submenu.id">
                            <div class="have-child-child-child">
                                <div @click="goSubmenu(index, submenu.url, submenu.id, submenu)">
                                    <img class="img" v-if="submenu.icon && submenu.icon != ''" :src="submenu.icon" style="width: 16px; height: 16px" />
                                    {{ submenu.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
export default {
    components: {
        LiefengContent,
    },
    data() {
        return {
            menuList: [],
            allMenuList: [],
            menuName: "",
        }
    },
    created() {
        var list = JSON.parse(window.sessionStorage.getItem("menuList")).filter(item => item.subMenus && item.subMenus.length)
        this.formatTree(list)
    },
    methods: {
        searchChild() {
            const that = this
            //深拷贝防止改变源数据
            let sourceDto = JSON.parse(JSON.stringify(that.allMenuList))
            if (that.menuName) {
                that.menuList = that.childrenFn(sourceDto, that.menuName)
            } else {
                that.menuList = sourceDto
            }
        },
        /**
         * @param arr 传入的树结构
         * @param key 需要搜索的关键字
         */
        childrenFn(arr, key) {
            const that = this
            let searchTree = []
            arr.forEach(item => {
                if (item.subMenus != undefined && item.subMenus.length != 0) {
                    let leaf = that.childrenFn(item.subMenus, key)
                    //如果叶子节点为空，说明没找到，去找一下叶子节点的上一级
                    if (leaf != undefined) {
                        searchTree.push({ ...item, isSelect: 0 })
                    } else {
                        //如果是中间节点有关键字，把中间节点作为叶子节点往回传
                        if (item.name.indexOf(key) != -1) {
                            delete item["subMenus"]
                            searchTree.push({ ...item, isSelect: 1 })
                        }
                    }
                } else {
                    //如果是最后一级，回传
                    if (item.name.indexOf(key) != -1) {
                        searchTree.push({ ...item, isSelect: 1 })
                    }
                }
            })
            if (searchTree != undefined && searchTree.length != 0) {
                return searchTree
            }
        },

        search() {
            this.searchChild()
            console.log(this.menuList)
        },
        reset() {
            this.menuName = ""
            this.menuList = this.allMenuList
        },
        goSubmenu(index, url, menuid, item) {
            var temp = null
            parent.vue.menuList.map((item, index) => {
                if (item.id == menuid) {
                    temp = index
                    console.log('如果第一层就符合',item);
                    
                } else {
                    if (item.subMenus && item.subMenus.length) {
                        item.subMenus.map(items => {
                            if (items.id == menuid) {
                                console.log('如果第二层就符合',items);
                                temp = index
                            } else {
                                if (items.subMenus && items.subMenus.length) {
                                    items.subMenus.map(itemss => {
                                        if (itemss.id == menuid) {
                                            console.log('如果第三层就符合',itemss);
                                            temp = index
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            })
            parent.vue.goSubmenu(temp, url, menuid, item)
        },
        formatTree(list) {
            this.menuList = this.allMenuList = list.map(item => {
                if (item.subMenus && item.subMenus.length) {
                    var lists = []
                    item.subMenus.map(items => {
                        if (items.subMenus && items.subMenus.length) {
                            items.subMenus.map(itemss => {
                                lists.push(itemss)
                            })
                            item.subMenus = lists
                        }
                        return items
                    })
                }
                return item
            })
        },
    },
}
</script>

<style lang="less" scoped>
.content {
    column-count: 4;
}
.item {
    break-inside: avoid;
    margin: 20px 0;
    // border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 10px 0;
    .have-child {
        margin: 20px;
        display: flex;
        flex-wrap: wrap;
        .have-child-child {
            cursor: pointer;
            user-select: none;
            border: 1px solid #ccc;
            padding: 10px;
            margin: 5px;
            border-radius: 5px;
            &:hover {
                background-color: #f2f2f2;
            }
        }
        .have-name {
            display: flex;
            align-items: center;
            width: 200px;
        }
    }
    .none-child {
        margin: 5px 0;
        .none-div {
            display: inline-block;
            // border: 1px solid #ccc;
            padding: 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            user-select: none;
            font-size: 18px;
        }
    }
}
</style>